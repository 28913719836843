@font-face {
  font-family: 'SF Pro Display';
  src: url(./web-font/SFPRODISPLAYREGULAR.OTF);
}

:root {
  /* ---- LIGHT MODE ---- */

  --light-mode-background: #fff;
  --light-mode-para: #6a0e8f;

  /* ---- DARK MODE ---- */

  --dark-mode-background: #131313;
  --dark-mode-para: #7c3cc4;

  /* ---- FONTS ----  */

  --web-font: 'SF Pro Display';
}

.dark-mode p {
  color: var(--dark-mode-para);
}

.dark-mode {
  background: var(--dark-mode-background);
}

.bg-image {
  background: url("./images/background.png") no-repeat center fixed;
  height: 100%;
  width: 100%;
  background-size: cover;
  font-family: 'SF Pro Display';
}

@media screen and (max-width:992px) {
  nav {
    position: relative;
    left: -30%;
    transition: 1s;
  }

  .navbar-toggler {
    display: none;
  }

  .card {
    display: block;
    margin: 0 auto;
  }

  .bg-image {
    background-blend-mode: darken;
    background: url(./images/background.png) center no-repeat fixed rgba(0, 0, 0, 0.5);
  }
}

.fw-bold {
  letter-spacing: 1px;
}

.dp-pic {
  max-width: 100%;
  width: 190px;
  height: 200px;
  border: 1px solid transparent;
  border-radius: 100px;
}

#about {
  margin-top: 120px;
}

body::-webkit-scrollbar {
  width: 0px;
  /* background: linear-gradient(45deg, #e923e9, #84368f ,#8e46c9, #b269ee); */
}

/* #portfolio {
  background: url(./images/portfolio-bg.webp);
  height: 100%;
  background-size: cover;
} */

/* .row-1 .col-md-4 .container-item {
  border: 6px solid transparent;
  border-image: linear-gradient(to right, #b510c4,cyan, #82df82);
  border-image-slice: 1;
  padding: 25px;
} */

.line {
  height: 300px;
  width: 8px;
}

.intern-img {
  max-width: 100%;
  width: 600px;
  float: right;
}

.profiles-img {
  max-width: 100%;
  width: 400px;
  /* margin-top: 200px; */
  margin: 0 auto;
}

.profiles-img-d {
  float: right;
}

@media only screen and (min-width: 1000px) {
  .profiles-img-d {
    position: relative;
    margin-top: 250px;
    margin-left: -20px;
  }
}

.about-profiles-ul {
  display: flex;
  list-style: none;
  margin-left: -20px;
}

.about-profiles-ul li {
  padding: 0px 15px;
}